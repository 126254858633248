import { FC, useCallback } from 'react';

// router
import { useHistory } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// assets
import style from '../assets/style/components/confirm.module.scss';

// 都道府県
import { prefectureNames } from 'jp-prefectures';

// type
import { ProfileValue, ProfileLabel } from '../@types/profile';

// function component
type Props = {
  isEditable?: boolean;
};
export const Confirm: FC<Props> = ({ isEditable }) => {
  const history = useHistory();

  const { profileOptions, draft } = useSelector((state: RootState) => state.user);

  // 都道府県名を都道府県コードと照らし合わせて取得
  const prefectureText: string[] = ['その他', ...prefectureNames()];

  const items: ProfileLabel[] = ['gender', 'age', 'prefecture', 'nickname'];
  const labels = ['性別', '年齢', '居住地', 'ニックネーム（希望する場合）'];
  // const items: ProfileLabel[] = ['gender', 'age', 'prefecture', 'occupation', 'nickname'];
  // const labels = ['性別', '年齢', '居住地', '職業', 'ニックネーム（希望する場合）'];

  const initial = {
    gender: '…',
    age: '…',
    prefecture: '…',
    // occupation: '…',
    nickname: '（匿名で紹介）',
  };

  const getLabel = (category: ProfileLabel, value: ProfileValue) => {
    const current = profileOptions[category].find(item => item.value === value);
    return current ? current.label : '';
  };

  const profileData: { [key: string]: string | JSX.Element } = draft
    ? {
        gender: getLabel('gender', draft.gender),
        age: getLabel('age', draft.age),
        prefecture: prefectureText[draft.prefecture as number],
        // occupation: getLabel('occupation', draft.occupation),
        nickname: draft.nickname || <span className={style.empty}>匿名で紹介</span>,
      }
    : initial;

  const doEdit = useCallback(
    (path: string) => {
      if (isEditable) history.push(path);
    },
    [history, isEditable]
  );

  return (
    <div className={style.confirm}>
      {items.map((item: string, index: number) => (
        <dl
          className={style.dl}
          key={item}
          onClick={() => {
            doEdit(`/profile/${item}`);
          }}
        >
          <dt className={style.dt}>{labels[index]}</dt>
          <dd className={style.dd}>
            {profileData ? profileData[item] : ''}

            {isEditable ? (
              <span className={style.editGuide}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={style.editIcon}
                  viewBox="0 0 24 24"
                >
                  <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                </svg>
                編集
              </span>
            ) : undefined}
          </dd>
        </dl>
      ))}
    </div>
  );
};
