import { useEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { updateInitialProfile } from '../store/user';
import { useGetInitialDataQuery } from '../store/survey';

// type
import { ProfileValue } from '../@types/profile';

export const useGetInitialData = (params: { userId: string; skip: boolean }): boolean => {
  const dispatch = useDispatch();

  const { userId, skip } = params;

  const { data, error, isSuccess, isError } = useGetInitialDataQuery(userId, { skip });

  useEffect(() => {
    if (isSuccess && data) {
      // プロフィール
      dispatch(updateInitialProfile(data.userAttributes as ProfileValue));
    } else if (error) {
      console.log('error', error);
    }
  }, [data, dispatch, error, isSuccess]);

  return !!!isError;
};
