import { FC, useEffect } from 'react';

// router
import { useParams, useHistory } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// assets
// import style from '../assets/style/pages/home.module.scss';

// component
import { Layout } from '../layout/layout';
import { Survey } from '../components/survey';
import { HasAnswer } from '../components/hasAnswer';

// type
interface Params {
  id: string;
}

// function component
export const Home: FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const { hasAnswer } = useSelector((state: RootState) => state.survey);

  // 送信完了
  useEffect(() => {
    if (hasAnswer) history.replace('/');
  }, [history, hasAnswer]);

  return <Layout>{hasAnswer ? <HasAnswer /> : <Survey id={id} />}</Layout>;
};
