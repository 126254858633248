import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

// type
import { News } from '../@types/news';
import { Answer, QuestionAnswer } from '../@types/answer';
import { ProfileValue } from '../@types/profile';
import { Question } from '../@types/question';
interface GTagWindow extends Window {
  gtagEvent: (eventName: string, eventProps?: { [key: string]: string | number }) => void;
}
declare const window: GTagWindow;

type PointData = {
  added: number; // 獲得ポイント
  validTotal?: number; // 保有ポイント
};

type State = {
  newsList: News[]; // ニュース一覧
  questionList: Question[]; // 質問一覧
  surveyAnswer: Answer; // 回答
  hasAnswer: boolean; // 回答済み判定
  isAccepting: boolean | null; // 受付時間判定
  point: PointData;
  errorType?: number; // エラーコード
  isPost: boolean; // アンケートPOST成功フラグ
};

type InitialResponse = {
  newsList?: News[]; // ニュース一覧
  questionList?: Question[]; // 質問一覧
  surveyAnswer?: Answer; // 気になるニュース回答
  userAttributes?: ProfileValue; // プロフィール
};

type SendAnswer = {
  userId: string;
  value: Answer;
};

const initialState: State = {
  newsList: [],
  questionList: [],
  surveyAnswer: {
    interests: [],
  },
  hasAnswer: false,
  isAccepting: null,
  point: {
    added: 0,
  },
  isPost: false,
};

// 受付時間判定
export const judgeAccepting = createAsyncThunk('judgeAccepting', async () => {
  const acceptingTimeText = process.env.REACT_APP_ACCEPTING_TIME as string;
  const acceptingDayOfWeekText = process.env.REACT_APP_ACCEPTING_DAYS as string;
  // 今の時間
  const now = new Date();
  const nowDateText = now.getFullYear() + '/' + (now.getMonth() + 1) + '/' + now.getDate();
  // 受付時間
  const acceptingTime = acceptingTimeText.split('-');
  const endDateTime = new Date(`${nowDateText} ${acceptingTime[1]}`);
  const params = {
    dayOfWeek: +now.getDay(), // 日曜:0, 月曜:1, 火曜:2, 水曜:3, 木曜:4, 金曜:5, 土曜:6
    startDateTime: +new Date(`${nowDateText} ${acceptingTime[0]}`),
    closedDateTime: +new Date(endDateTime.setMinutes(endDateTime.getMinutes() + 1)),
  };
  // 受付曜日
  const acceptingDayOfWeek: number[] = [];
  acceptingDayOfWeekText.split(',').forEach(day => {
    acceptingDayOfWeek.push(+day);
  });
  // ホスティングサーバの時間を取得し比較する
  const server = `${window.location.protocol}//${window.location.host}/`;
  const result = await axios.head(server).then(res => {
    // 曜日が受付期間内か判定
    const validDayOfWeek = acceptingDayOfWeek.includes(params.dayOfWeek);

    // 受付期間内の判定
    const serverTime = new Date(res.headers.date);
    const validStart = +serverTime >= params.startDateTime;
    const validEnd = +serverTime <= params.closedDateTime;

    const valid = validDayOfWeek && validStart && validEnd;

    return {
      isAccepting: valid,
    };
  });
  return (await result) as { isAccepting?: boolean };
});

// RTK Queryの設定
// https://redux-toolkit.js.org/rtk-query/overview
const apiKey = process.env.REACT_APP_API_KEY || '';
const baseUrl = process.env.REACT_APP_API_ENDPOINT || '';
export const surveyApi = createApi({
  reducerPath: 'surveyApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: builder => ({
    // 起動時に状態を取得
    getInitialData: builder.query<InitialResponse, string>({
      query: userId => ({
        url: `/survey/data`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
          'line-authorization': userId,
        },
      }),
    }),
    // 回答を送信
    sendAnswer: builder.mutation<number[], SendAnswer>({
      query: ({ userId, value }) => ({
        url: `/survey/answer`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
          'line-authorization': userId,
        },
        body: value,
      }),
    }),
  }),
});

// エンドポイント毎にカスタムhooksが作成される
export const { useGetInitialDataQuery, useSendAnswerMutation } = surveyApi;

const survey = createSlice({
  name: 'survey',

  initialState,

  reducers: {
    // 気になるニュース
    updateInterests: (state, action: PayloadAction<number[]>) => {
      const result: {
        interests: number[];
      } = {
        interests: action.payload,
      };
      return {
        ...state,
        surveyAnswer: { ...state.surveyAnswer, ...result },
      };
    },
    // 最も気になるニュース
    updateMostInterested: (state, action: PayloadAction<number | null>) => {
      const result: {
        mostInterested: number | null;
      } = {
        mostInterested: action.payload,
      };
      return {
        ...state,
        surveyAnswer: { ...state.surveyAnswer, ...result },
      };
    },
    // コメント
    updateComment: (state, action: PayloadAction<string>) => {
      const result: {
        comment: string;
      } = {
        comment: action.payload,
      };
      return {
        ...state,
        surveyAnswer: { ...state.surveyAnswer, ...result },
      };
    },
    // 質問への回答を更新
    updateQuestionAnswerList: (state, action: PayloadAction<QuestionAnswer>) => {
      const extraQuestionAnswerList = state.surveyAnswer.questionAnswerList
        ? state.surveyAnswer.questionAnswerList.filter(
            item => item.questionId !== action.payload.questionId
          )
        : [];
      const questionAnswerList: QuestionAnswer[] = [...extraQuestionAnswerList, action.payload];
      const extraSurveyAnswer = { ...state.surveyAnswer };
      return {
        ...state,
        surveyAnswer: { ...extraSurveyAnswer, questionAnswerList },
      };
    },
    // エラーステータスをリセット
    resetError: (state, action) => {
      const result: {
        errorType?: number;
        isAccepting?: boolean;
      } = {
        errorType: undefined,
      };
      if (action.payload === 403) result.isAccepting = false;
      return {
        ...state,
        ...result,
      };
    },
  },

  extraReducers: builder => {
    // 受付時間判定：成功
    builder.addCase(judgeAccepting.fulfilled, (state, action) => {
      const result: { isAccepting: boolean } = action.payload as { isAccepting: boolean };
      // GA：受付時間内のアクセス or 受付時間外のアクセス
      window.gtagEvent(result.isAccepting ? 'isAccepting' : 'unAccepting');
      return {
        ...state,
        ...result,
      };
    });
    // 受付時間判定：処理に失敗
    builder.addCase(judgeAccepting.rejected, state => {
      const result: { isAccepting: boolean } = { isAccepting: false };
      return {
        ...state,
        ...result,
      };
    });
    // 初期値を取得：成功
    builder.addMatcher(
      surveyApi.endpoints.getInitialData.matchFulfilled,
      (state, action: PayloadAction<InitialResponse>) => {
        const result: {
          newsList?: News[];
          questionList?: Question[];
          surveyAnswer?: Answer;
        } = {
          newsList: action.payload.newsList,
          questionList:
            action.payload.questionList && action.payload.questionList.length
              ? action.payload.questionList
              : undefined,
          surveyAnswer: action.payload.surveyAnswer,
        };
        const surveyAnswer = Object.assign({}, action.payload.surveyAnswer) as {
          [key: string]: ProfileValue;
        };
        const hasAnswer = !!Object.keys(surveyAnswer).length;
        if (!hasAnswer) result.surveyAnswer!.interests = [];
        return {
          ...state,
          ...result,
          hasAnswer,
        };
      }
    );
    // 初期値を取得：失敗
    builder.addMatcher(surveyApi.endpoints.getInitialData.matchRejected, () => {
      // GA：初期値取得に失敗
      window.gtagEvent('error_initialize');
    });
    // 回答を送信：成功
    builder.addMatcher(
      surveyApi.endpoints.sendAnswer.matchFulfilled,
      (state, action: PayloadAction<{ result: boolean; point: PointData }>) => {
        console.log(action.payload?.point);
        // GA：回答を送信
        window.gtagEvent('send_answer');

        // GA：コメントして回答を送信
        if (state.surveyAnswer.comment) window.gtagEvent('send_comment');

        return {
          ...state,
          hasAnswer: true,
          point: action.payload?.point,
          isPost: true,
        };
      }
    );
    // 回答を送信：失敗
    builder.addMatcher(surveyApi.endpoints.sendAnswer.matchRejected, (state, action) => {
      // GA：初期値取得に失敗
      window.gtagEvent('error_answer');

      return {
        ...state,
        errorType: action.payload?.status as number | undefined,
      };
    });
  },
});

// Action Creators
export const {
  updateInterests,
  updateMostInterested,
  updateComment,
  updateQuestionAnswerList,
  resetError,
} = survey.actions;

// Reducer
export default survey.reducer;
