import { FC, memo, useCallback } from 'react';

// router

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { updateInterests, updateMostInterested, updateComment } from '../store/survey';
import { updateOpen, updateContent } from '../store/dialog';

// assets
import style from '../assets/style/components/surveyInterests.module.scss';
import articleStyle from '../assets/style/components/survey.module.scss';
import dialogStyle from '../assets/style/layout/dialog.module.scss';

// type

// function component
export const SurveyInterests: FC = () => {
  const dispatch = useDispatch();
  const { newsList } = useSelector((state: RootState) => state.survey);
  const { interests, mostInterested, comment } = useSelector(
    (state: RootState) => state.survey.surveyAnswer
  );

  // コメント中ニュースのチェック外し確認ダイアログ
  type ConfirmCheckClearProps = {
    id: number;
  };
  const ConfirmCheckClear = memo<ConfirmCheckClearProps>(({ id }) => {
    // 実行
    const doAccept = useCallback(() => {
      // コメント削除
      if (mostInterested === id) {
        dispatch(updateComment(''));
        dispatch(updateMostInterested(null));
      }
      dispatch(updateInterests(interests.filter(item => item !== id)));
      dispatch(updateOpen(false)); // 閉じる
    }, [id]);

    // キャンセル
    const doCancel = useCallback(() => {
      dispatch(updateOpen(false)); // 閉じる
    }, []);

    return (
      <>
        <div className={dialogStyle.body}>
          <p className={dialogStyle.description}>
            チェックを外して編集中のコメントも取り消しますか？
          </p>
        </div>
        <div className={dialogStyle.confirmFooter}>
          <button className={dialogStyle.buttonCancel} type="button" onClick={doCancel}>
            キャンセル
          </button>
          <button className={dialogStyle.buttonAccept} type="button" onClick={doAccept}>
            OK
          </button>
        </div>
      </>
    );
  });

  // チェック
  const doCheck = useCallback(
    e => {
      const checked = e.target.checked;
      const id = +e.target.value;

      if (checked) dispatch(updateInterests([...interests, id]));
      else {
        // コメント未入力
        if (!comment) {
          // 未入力 かつ いちばん気になったニュース 選択中
          if (mostInterested === id) dispatch(updateMostInterested(null));
          // チェック外す
          dispatch(updateInterests(interests.filter(item => item !== id)));
        } else {
          // いちばん気になったニュース 選択中
          if (mostInterested === id) {
            // チェックを外しコメントもクリアしていいか確認
            dispatch(updateContent(<ConfirmCheckClear id={id} />));
            dispatch(updateOpen(true));
          } else {
            // チェック外す
            dispatch(updateInterests(interests.filter(item => item !== id)));
          }
        }
      }
    },
    [ConfirmCheckClear, comment, dispatch, interests, mostInterested]
  );

  return (
    <article className={articleStyle.article}>
      <header>
        <h2 className={articleStyle.question}>気になるニュースをすべて選んでください</h2>
      </header>
      <div className={articleStyle.article__body}>
        <ul className={style.list}>
          {newsList?.map(news => {
            const { newsId, newsTitle } = news;
            const value = interests ? interests.includes(newsId) : undefined;
            return (
              <li className={style.item} key={newsId}>
                <input
                  className={style.checkbox}
                  type="checkbox"
                  name="interests"
                  id={`news${newsId}`}
                  value={newsId}
                  checked={value}
                  onChange={doCheck}
                />
                <label className={style.label} htmlFor={`news${newsId}`}>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="none"
                      d="M1.73,12.91 8.1,19.28 22.79,4.59"
                      className={style.done}
                    ></path>
                  </svg>
                  {newsTitle}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </article>
  );
};
