import { FC, useState, useMemo } from 'react';

// components
import { Layout } from '../layout/layout';

// assets
import style from '../assets/style/components/loading.module.scss';

// function component
type Props = {
  status: 'login' | 'loading' | 'error';
};

export const Loading: FC<Props> = ({ status }) => {
  const loadPath =
    'M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z';
  const errorPath =
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z';

  const [classList, setClassList] = useState<string[]>([style.icon]);

  useMemo(() => {
    setClassList(status !== 'error' ? [style.icon, style.isLoading] : [style.icon]);
  }, [status]);

  return (
    <Layout customFooter={' '}>
      <p className={style.status}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={classList.join(' ')}>
          <path className={style.path} d={status !== 'error' ? loadPath : errorPath} />
        </svg>
      </p>
    </Layout>
  );
};
