import { FC, useEffect, useMemo, useCallback, useRef, useState } from 'react';

// router
import { useHistory } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { updateMostInterested, updateComment } from '../store/survey';

// hooks
import { useDeleteEmoji } from '../hooks/useDeleteEmoji';

// assets
import articleStyle from '../assets/style/components/survey.module.scss';
import style from '../assets/style/components/surveyComment.module.scss';
import styleConfirm from '../assets/style/components/confirm.module.scss';

// type

// function component
export const SurveyComment: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { newsList } = useSelector((state: RootState) => state.survey);
  const { interests, mostInterested, comment } = useSelector(
    (state: RootState) => state.survey.surveyAnswer
  );

  // ニックネーム
  const nickname = useSelector((state: RootState) => state.user?.draft?.nickname);

  const deleteEmoji = useDeleteEmoji; // Emoji除去

  // コメント対象となりうるニュースの一覧
  const interestsNewsList = useMemo(() => {
    return newsList.filter(news => interests.includes(news.newsId));
  }, [interests, newsList]);

  // コメント入力欄
  const commentInput = useRef<HTMLTextAreaElement>(null);
  const commentLimit = 500;
  const [commentRemaining, setCommentRemaining] = useState<number>(500);
  const updateLimit = useCallback(
    (value: string): void => {
      const preText = deleteEmoji(value ?? '').slice(0, 500);
      setCommentRemaining(() => commentLimit - preText.length);
    },
    [deleteEmoji]
  );

  // いちばん気になるニュースを指定
  const doChange = useCallback(
    e => {
      const id = +e.currentTarget.value;
      dispatch(updateMostInterested(id));
    },
    [dispatch]
  );

  // コメントの書き込み
  const doComment = useCallback(
    e => {
      const newValue = e.target.value as string;
      const preText = deleteEmoji(newValue ?? '').slice(0, 500);
      dispatch(updateComment(preText));
    },
    [dispatch, deleteEmoji]
  );

  // フォーカスが外れたタイミングで入力欄の値を反映
  const doBlur = useCallback(
    e => {
      e.target.value = comment ?? '';
    },
    [comment]
  );

  // ページを表示したとき状態管理で持っている値をラジオボタンのchecked代替として選択中の表示を再現させる
  const isChecked = useCallback(
    (value): boolean => {
      return mostInterested === value;
    },
    [mostInterested]
  );

  // コメント入力textareaの監視
  useEffect(() => {
    // ページ読込時にコメントの残り文字数を反映
    if (comment !== undefined) updateLimit(comment);
    // 状態管理しているコメントの値が空になったらtextareaを空欄にする
    if (commentInput && commentInput.current && comment === '') commentInput.current.value = '';
  }, [comment, commentInput, updateLimit]);

  return (
    <article className={articleStyle.article}>
      <header>
        <h2 className={articleStyle.question}>
          {interests.length > 1
            ? 'さらに「1番気になったニュース」を選んで'
            : 'あなたが選んだニュースへの'}
          <br />
          『疑問』『意見』『感想』などを具体的に記入してください
          <small>（任意）</small>
        </h2>
      </header>

      <div className={articleStyle.article__body}>
        {interests.length > 1 ? (
          <ul className={style.list}>
            {interestsNewsList?.map(news => {
              const { newsId, newsTitle } = news;
              const value = interests ? interests.includes(newsId) : undefined;
              return (
                <li className={style.item} key={newsId}>
                  <input
                    className={articleStyle.radio}
                    type="radio"
                    id={`mostInterestedNews${newsId}`}
                    name="mostInterested"
                    value={newsId}
                    defaultChecked={mostInterested === value}
                    onChange={doChange}
                  />
                  <label
                    data-checked={isChecked(newsId)}
                    className={articleStyle.radioLabel}
                    htmlFor={`mostInterestedNews${newsId}`}
                  >
                    {newsTitle}
                  </label>
                </li>
              );
            })}
          </ul>
        ) : undefined}

        {mostInterested ? (
          <>
            <p className={style.headline}>
              {newsList?.find(news => news.newsId === mostInterested)?.newsTitle}
            </p>
          </>
        ) : undefined}

        <textarea
          ref={commentInput}
          onInput={doComment}
          onBlur={doBlur}
          className={articleStyle.textarea}
          cols={40}
          rows={8}
          defaultValue={comment}
          disabled={!mostInterested}
          placeholder={!mostInterested ? 'コメントするニュースを選んでください' : undefined}
        ></textarea>
        <div className={articleStyle.textareaCounter}>
          {commentRemaining} &#047; {commentLimit}
        </div>
      </div>

      <dl
        className={styleConfirm.dl}
        onClick={() => {
          history.push('/profile/nickname');
        }}
      >
        <dt className={styleConfirm.dt}>ニックネーム（希望する場合）</dt>
        <dd className={styleConfirm.dd}>
          {nickname ? <>{nickname}</> : <span className={styleConfirm.empty}>匿名で紹介</span>}

          <span className={styleConfirm.editGuide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={styleConfirm.editIcon}
              viewBox="0 0 24 24"
            >
              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
            </svg>
            編集
          </span>
        </dd>
      </dl>
    </article>
  );
};
