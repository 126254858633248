import { FC, useMemo, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { judgeAccepting } from '../store/survey';

// hooks
import { useAuthLiff } from '../hooks/useAuthLiff';
import { useGetInitialData } from '../hooks/useGetInitialData';

// component
import { Private } from './private';
import { Loading } from '../components/loading';

export const Routes: FC = () => {
  const dispatch = useDispatch();

  const { isUserRegistered } = useSelector((state: RootState) => state.user);
  const { isAccepting } = useSelector((state: RootState) => state.survey);

  // LIFFにログインする
  const { response, loading, error } = useAuthLiff();

  // ユーザデータ取得
  const [hasUserId, setHasUserId] = useState<boolean>(false); // GETリクエスト実行可否フラグ
  const successInitialize = useGetInitialData({ userId: response, skip: !!!hasUserId });

  const initialize = useMemo(() => {
    let result = <Loading status="login" />;
    if (!loading && (response || error)) {
      if (response) {
        result = <Loading status="loading" />;
        setHasUserId(true);
        // アンケート受付中かどうか判定
        if (isUserRegistered !== null && isAccepting === null) dispatch(judgeAccepting());
      }
      if (error || !successInitialize) result = <Loading status="error" />;
    }
    if (isUserRegistered !== null && isAccepting !== null) {
      // コンテンツを表示
      result = <Private isUserRegistered={isUserRegistered} isAccepting={isAccepting} />;
    }
    return result;
  }, [loading, response, error, successInitialize, isUserRegistered, isAccepting, dispatch]);

  // return <>test</>;
  return initialize;
};
