import { FC, useState, useMemo } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// assets
import style from '../assets/style/layout/dialog.module.scss';

// function component
export const Dialog: FC = () => {
  const { isOpen, content } = useSelector((state: RootState) => state.dialog);
  const className = (useSelector((state: RootState) => state.dialog.class) ?? []) as string[];

  const [parentClass, setParentClass] = useState<string[]>([style.dialog]);

  useMemo(() => {
    setParentClass(isOpen ? [style.dialog, style.isOpen] : [style.dialog]);
  }, [isOpen]);

  return (
    <div className={parentClass.join(' ')}>
      <div className={style.screen}></div>
      <div className={[style.content, style.basic, ...className].join(' ')}>{content}</div>
    </div>
  );
};
