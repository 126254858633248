import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// 都道府県
import { prefectures } from 'jp-prefectures';

// type
import { ProfileOptions, Profile, ProfileValue } from '../@types/profile';
interface GTagWindow extends Window {
  gtagEvent: (eventName: string, eventProps?: { [key: string]: string | number }) => void;
}
declare const window: GTagWindow;

type State = {
  userId?: string;
  data?: Profile;
  draft?: Profile;
  isSaved?: boolean;
  closingDatetime?: string;
  isUserRegistered: boolean | null;
  profileOptions: ProfileOptions;
};

type SendData = {
  userId: string;
  data: Profile;
};

const prefectureOption: { label: string; value: ProfileValue }[] = [];
prefectures().forEach(item => {
  prefectureOption.push({ label: item.name, value: item.code });
});
prefectureOption.push({ label: 'その他', value: 0 });

const initialState: State = {
  profileOptions: {
    gender: [
      { label: '男性', value: 1 },
      { label: '女性', value: 2 },
      { label: 'その他', value: 0 },
    ],
    age: [
      { label: '9歳以下', value: 0 },
      { label: '10～19歳', value: 1 },
      { label: '20～29歳', value: 2 },
      { label: '30～39歳', value: 3 },
      { label: '40～49歳', value: 4 },
      { label: '50～59歳', value: 5 },
      { label: '60歳以上', value: 6 },
    ],
    prefecture: prefectureOption,
    occupation: [
      { label: '1次産業', description: '農業、林業、漁業', value: 1 },
      { label: '製造業', description: '', value: 2 },
      {
        label: 'IT・専門職',
        description: '情報通信、運輸、郵便、研究',
        value: 3,
      },
      { label: '建設・不動産', description: '', value: 4 },
      { label: '販売', description: '卸売、小売', value: 5 },
      { label: '金融・公務', description: '金融、保険、教育、公務', value: 6 },
      { label: '医療福祉', description: '', value: 7 },
      {
        label: 'サービス業',
        description: '宿泊、飲食、洗濯、理容、娯楽',
        value: 0,
      },
    ],
  },
  isUserRegistered: null,
};

const apiKey = process.env.REACT_APP_API_KEY || '';
const baseUrl = process.env.REACT_APP_API_ENDPOINT || '';

// RTK Queryの設定
// https://redux-toolkit.js.org/rtk-query/overview
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: builder => ({
    // ユーザー続映保存
    sendProfile: builder.mutation<Profile, SendData>({
      query: ({ userId, data }) => ({
        url: `/user`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
          'line-authorization': userId,
        },
        body: { ...data },
      }),
    }),
  }),
});

// エンドポイント毎にカスタムhooksが作成される
export const { useSendProfileMutation } = userApi;

const user = createSlice({
  name: 'user',

  initialState,

  reducers: {
    // LIFF.initで取得したユーザーIDを保存
    updateUserId: (state, action: PayloadAction<string>) => {
      const result: {
        userId: string;
      } = {
        userId: action.payload,
      };
      return {
        ...state,
        ...result,
      };
    },
    // 初期データを保存
    updateInitialProfile: (state, action: PayloadAction<ProfileValue>) => {
      const data = { ...action.payload };
      const draft = { ...action.payload };
      const isUserRegistered = action.payload ? !!Object.keys(action.payload).length : false;
      // GA：新規、ユーザ情報設定済
      window.gtagEvent(!isUserRegistered ? 'guest' : 'member');
      return {
        ...state,
        data,
        draft,
        isUserRegistered,
        isSaved: true,
      };
    },
    // 初回登録完了
    updateIsUserRegistered: state => {
      return {
        ...state,
        isUserRegistered: true,
      };
    },
    // 下書きを更新
    updateDraft: (state, action: PayloadAction<{ label: string; value: ProfileValue }>) => {
      const { label, value } = action.payload;
      const draft: { [key: string]: ProfileValue } = Object.assign({}, state.draft);
      draft[label] = value;
      return {
        ...state,
        draft,
        isSaved: false,
      };
    },
  },

  extraReducers: builder => {
    // ユーザー属性保存：成功
    builder.addMatcher(userApi.endpoints.sendProfile.matchFulfilled, state => {
      const data = state.draft as Profile;
      // GA：新規会員登録、会員情報更新
      window.gtagEvent(!state.isUserRegistered ? 'sign_up' : 'update_profile', { method: 'LIFF' });
      return {
        ...state,
        data,
        isSaved: true,
      };
    });
    // ユーザー属性保存：失敗
    builder.addMatcher(userApi.endpoints.sendProfile.matchRejected, () => {
      // GA：ユーザー属性保存に失敗
      window.gtagEvent('error_profile');
    });
  },
});

// Action Creators
export const { updateUserId, updateInitialProfile, updateIsUserRegistered, updateDraft } =
  user.actions;

// Reducer
export default user.reducer;
