import { FC } from 'react';

// assets
import style from '../assets/style/components/stepNavigation.module.scss';

// function component
type Props = {
  step: number;
};

export const StepNavigation: FC<Props> = ({ step }) => {
  const labels = ['性別', '年齢', '居住地', 'ニックネーム'];
  // const labels = ['性別', '年齢', '居住地', '職業', 'ニックネーム'];
  return (
    <nav className={style.flow}>
      <ol className={style.stepList}>
        {labels.map((name, index) => (
          <li
            className={style.step}
            aria-current={step >= index + 1 ? 'page' : undefined}
            key={index}
          >
            <svg viewBox="0 0 24 24" className={style.done}>
              <path fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59" className={style.icon}></path>
            </svg>
            <span className={style.stepLabel}>{name}</span>
          </li>
        ))}
      </ol>
    </nav>
  );
};
