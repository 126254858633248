import { FC, memo, useEffect, useMemo, useCallback } from 'react';

// router
import { useHistory, useParams } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useSendProfileMutation } from '../../store/user';
import { updateOpen, updateContent } from '../../store/dialog';

// assets
import style from '../../assets/style/pages/profile.module.scss';
import dialogStyle from '../../assets/style/layout/dialog.module.scss';

// component
import { Layout } from '../../layout/layout';
import { EditGender } from '../../components/editGender';
import { EditAge } from '../../components/editAge';
import { EditPrefecture } from '../../components/editPrefecture';
import { EditOccupation } from '../../components/editOccupation';
import { EditNickname } from '../../components/editNickname';
import { TermsOfUse } from '../../components/termsOfUse';

// type
import { Profile, ProfileLabel, ProfileValue } from '../../@types/profile';

// function component
interface ParamTypes {
  id: ProfileLabel;
}
export const ProfileEdit: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams<ParamTypes>();

  const { userId, data, draft } = useSelector((state: RootState) => state.user);

  const labels = ['gender', 'age', 'prefecture', 'occupation', 'nickname'] as ProfileLabel[];

  // 送信結果ダイアログ
  type ResultDialogProps = { isSuccess: boolean };
  const ResultDialog = memo<ResultDialogProps>(({ isSuccess }) => {
    // 閉じる
    const doClose = useCallback(() => {
      if (isSuccess) history.goBack();
      if (!isSuccess) doCancel();
      dispatch(updateOpen(false)); // 閉じる
    }, [isSuccess]);

    return (
      <>
        <div className={dialogStyle.body}>
          <p className={dialogStyle.description}>
            {isSuccess ? '更新しました' : '更新に失敗しました'}
          </p>
        </div>
        <div className={dialogStyle.confirmFooter}>
          <button className={dialogStyle.buttonCancel} type="button" onClick={doClose}>
            閉じる
          </button>
        </div>
      </>
    );
  });

  // プロフィールを送信
  const [
    sendProfile, // mutation trigger
    { isLoading: isUpdatingLoad, isError: isUpdatingError, isSuccess: isUpdatingSuccess }, // mutation state
  ] = useSendProfileMutation();
  const doUpdate = useCallback(() => {
    const sendData = {
      userId: userId as string,
      data: { ...draft } as Profile,
    };
    sendProfile(sendData);
  }, [draft, sendProfile, userId]);
  useEffect(() => {
    if (isUpdatingSuccess) dispatch(updateContent(<ResultDialog isSuccess={true} />));
    if (isUpdatingError) dispatch(updateContent(<ResultDialog isSuccess={false} />));
    if (isUpdatingSuccess || isUpdatingError) {
      // ダイアログで告知
      dispatch(updateOpen(true));
    }
  }, [history, isUpdatingSuccess, isUpdatingError, dispatch, ResultDialog]);

  // キャンセル
  const doCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  // 書き換える場合だけ送信ボタンを活性化
  const isDisabled = useMemo((): boolean => {
    const defaultValue = Object.assign({}, data) as { [key: string]: ProfileValue };
    const newValue = Object.assign({}, draft) as { [key: string]: ProfileValue };
    return defaultValue[id] === newValue[id] || isUpdatingLoad;
  }, [data, draft, id, isUpdatingLoad]);

  // ヘッダー
  const customHeader = (
    <div className={style.information}>
      <h2 className={style.pageTitle}>プロフィール</h2>
    </div>
  );

  // 存在しないプロフィール項目のアドレスを入力した場合、トップへリダイレクト
  useEffect(() => {
    if (!labels.includes(id)) history.replace('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout customHeader={customHeader}>
      <div className={style.profile}>
        {id === 'gender' ? (
          <EditGender />
        ) : id === 'age' ? (
          <EditAge />
        ) : id === 'prefecture' ? (
          <EditPrefecture />
        ) : id === 'occupation' ? (
          <EditOccupation />
        ) : id === 'nickname' ? (
          <EditNickname />
        ) : (
          <></>
        )}
        <div className={style.footer}>
          <button className={style.submit} type="button" onClick={doUpdate} disabled={isDisabled}>
            変更する
          </button>
          <span className={style.cancel} onClick={doCancel}>
            キャンセルする
          </span>
        </div>
      </div>
      <TermsOfUse />
    </Layout>
  );
};
