// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// type
import { ProfileValue, ProfileLabel } from '../@types/profile';

// プロフィールの表記を返す
export const useProfileLabel = (params: {
  category: ProfileLabel;
  value: ProfileValue;
}): string => {
  const { category, value } = params;

  // 選択肢
  const { profileOptions } = useSelector((state: RootState) => state.user);

  const current = profileOptions[category].find(item => item.value === value);
  const result = current ? current.label : '';

  return result;
};
