import { FC, memo, useEffect, useMemo, useCallback, FormEvent } from 'react';

// router
import { useHistory } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import {
  updateMostInterested,
  useSendAnswerMutation,
  judgeAccepting,
  resetError,
} from '../store/survey';
import { updateOpen, updateContent } from '../store/dialog';

// components
import { SurveyInterests } from '../components/surveyInterests';
import { SurveyComment } from '../components/surveyComment';
import { SurveySingleChoice } from '../components/surveySingleChoice';

// assets
import style from '../assets/style/components/survey.module.scss';
import dialogStyle from '../assets/style/layout/dialog.module.scss';

// function component
type Props = {
  id?: string;
};
export const Survey: FC<Props> = (props: Props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { userId } = useSelector((state: RootState) => state.user);
  const { isAccepting, questionList, errorType } = useSelector((state: RootState) => state.survey);
  const { interests, mostInterested, comment, questionAnswerList } = useSelector(
    (state: RootState) => state.survey.surveyAnswer
  );

  // 気になるニュース選択
  const isInterestsLocation = useMemo(
    () => history.location.pathname === '/',
    [history.location.pathname]
  );

  // 気になるニュースのコメント入力
  const isCommentLocation = useMemo(
    () => !!history.location.pathname.match(/comment/g),
    [history.location.pathname]
  );
  // 気になるニュース選択0件状態のhistory.back対策
  if (isCommentLocation && interests.length < 1) history.replace('/');

  // 質問
  const isQuestionLocation = useMemo(
    () => !!history.location.pathname.match(/question/g),
    [history.location.pathname]
  );

  // 質問の既存回答
  const currentQuestionAnswer = useMemo(
    () =>
      id && questionAnswerList ? questionAnswerList.find(q => q.questionId === +id) : undefined,
    [id, questionAnswerList]
  );

  // 最後のページ
  const finishLocation = useMemo(
    () =>
      !questionList ? '/comment' : `/question/${questionList[questionList.length - 1].questionId}`,
    [questionList]
  );

  // アンケート実施期間の表記を生成
  const AcceptingInformation = memo(() => {
    const acceptingTimeText = (process.env.REACT_APP_ACCEPTING_TIME || '') as string; // 受付時間

    const now = new Date();
    const nowYear = now.getFullYear();
    const nowMonth = now.getMonth() + 1;
    const nowDate = now.getDate();
    return (
      <dl className={style.dl}>
        <dt>実施期間</dt>
        <dd>
          {nowYear}年{nowMonth}月{nowDate}日 {acceptingTimeText.replace(/-/g, ' - ')}
        </dd>
      </dl>
    );
  });

  // 回答を送信
  const [
    sendAnswer, // mutation trigger
    { isLoading: isUpdating }, // mutation state
  ] = useSendAnswerMutation();

  // 受付可能時間の判定
  const judgeAcceptingTime = (e: FormEvent) => {
    e.preventDefault();
    dispatch(judgeAccepting());
    if (isAccepting) doAnswer();
  };

  // 回答送信
  const doAnswer = useCallback(() => {
    const validMostInterested = comment && comment.length > 0 && mostInterested !== null;
    const value = {
      interests,
      mostInterested: !!validMostInterested ? mostInterested : undefined,
      comment: comment && comment.length > 0 ? comment : undefined,
      questionAnswerList,
    };
    const sendData = {
      userId: userId as string,
      value,
    };
    sendAnswer(sendData);
  }, [comment, interests, mostInterested, questionAnswerList, sendAnswer, userId]);

  // エラーダイアログ
  type ErrorMessageProps = {
    status: number;
  };
  const ErrorMessage = memo<ErrorMessageProps>(({ status }) => {
    // ダイアログを閉じる
    const doClose = () => {
      dispatch(updateOpen(false));
      dispatch(resetError(status));
    };
    const defaultMessage = (
      <>
        <div className={dialogStyle.body}>
          <p className={dialogStyle.title}>通信エラー</p>
          <p className={dialogStyle.description}>アンケートの送信に失敗しました</p>
        </div>
        <div className={dialogStyle.confirmFooter}>
          <button className={dialogStyle.buttonCancel} type="button" onClick={doClose}>
            閉じる
          </button>
        </div>
      </>
    );

    // 受付時間外
    const outOfService = (
      <>
        <div className={dialogStyle.body}>
          <p className={dialogStyle.title}>アンケート回答時間外です</p>
          <p className={dialogStyle.description}>アンケート回答の受付を締切りました。</p>
          <p className={dialogStyle.description}>
            ニュース関心調査は、放送前日のよる7時〜9時に実施中です🌈
          </p>
        </div>
        <div className={dialogStyle.confirmFooter}>
          <button className={dialogStyle.buttonCancel} type="button" onClick={doClose}>
            閉じる
          </button>
        </div>
      </>
    );

    return status === 403 ? outOfService : defaultMessage;
  });

  // 回答送信にエラーが発生したときの処理
  useEffect(() => {
    if (errorType) {
      // エラーメッセージ
      dispatch(updateContent(<ErrorMessage status={errorType} />));
      dispatch(updateOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);

  // 次へ
  const updateStep = useCallback(() => {
    // 改ページのタイミングで気になるニュースを監視
    // 気になるニュース選択 → コメント
    if (isInterestsLocation) {
      // 気になるニュース1件だけの場合は自動でいちばん気になるニュースに選択
      if (interests.length === 1) dispatch(updateMostInterested(interests[0]));
      // URL書換
      history.push('/comment');
    }
    // コメント → 質問
    if (isCommentLocation) {
      // URL書換
      history.push(`/question/${questionList[0].questionId}`);
    }
    // 質問 → 質問
    if (isQuestionLocation && id) {
      const current = questionList.find(q => q.questionId === +id);
      const currentIndex = current ? questionList.indexOf(current) : 0;

      // URL書換
      history.push(`/question/${questionList[currentIndex + 1].questionId}`);
    }
  }, [
    id,
    dispatch,
    history,
    interests,
    isCommentLocation,
    isInterestsLocation,
    isQuestionLocation,
    questionList,
  ]);

  // 戻る
  const backStep = useCallback(() => {
    // 改ページのタイミングで気になるニュースを監視
    // コメント → 気になるニュース選択
    if (isCommentLocation) {
      // コメントが空欄の場合は、いちばん気になるニュースの選択も解除
      if (comment === '' || comment === undefined) dispatch(updateMostInterested(null));
      // URL書換
      history.push('/');
    }
    // 質問 → コメント
    if (history.location.pathname === `/question/${questionList[0].questionId}`) {
      // URL書換
      history.push('/comment');
    }
    // 質問 → 質問
    else if (isQuestionLocation && questionList && id) {
      const current = questionList.find(q => q.questionId === +id);
      const currentIndex = current ? questionList.indexOf(current) : 0;
      // URL書換
      if (current) history.push(`/question/${questionList[currentIndex - 1].questionId}`);
    }
  }, [comment, dispatch, history, id, isCommentLocation, isQuestionLocation, questionList]);

  // ページ遷移を監視
  useEffect(() => {
    // 気になるニュースのコメント入力
    const isCommentLocation = !!history.location.pathname.match(/comment/g);

    // 気になるニュース選択0件状態のhistory.back対策
    if (isCommentLocation && interests.length < 1) history.replace('/');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  // 送信ボタン
  const sendButton = useMemo(() => {
    // 送信制御
    const isFinish = finishLocation === history.location.pathname;

    // 非活性制御
    const unselectInterests = interests.length < 1; // 気になるニュースが選択されていない
    const unselectQuestion =
      questionAnswerList && currentQuestionAnswer
        ? currentQuestionAnswer?.answer === undefined
        : true; // 質問の回答が選択されていない
    const disabled = !isQuestionLocation ? unselectInterests : unselectQuestion;

    return (
      <button
        type="button"
        onClick={isFinish ? judgeAcceptingTime : updateStep}
        className={style.submit}
        disabled={disabled || isUpdating}
      >
        {isFinish ? '回答する' : '次へ'}
      </button>
    );
  }, [
    finishLocation,
    history.location.pathname,
    interests.length,
    questionAnswerList,
    currentQuestionAnswer,
    isQuestionLocation,
    judgeAcceptingTime,
    updateStep,
    isUpdating,
  ]);

  return (
    <>
      {!isQuestionLocation && (
        <>
          <div className={style.information}>
            <h2 className={style.pageTitle}>
              ニュースへの興味関心を
              <br />
              教えてください
            </h2>
          </div>
          <AcceptingInformation />
        </>
      )}
      <form action="">
        {isInterestsLocation && (
          <>
            <SurveyInterests />
            {sendButton}
          </>
        )}

        {isCommentLocation && (
          <>
            <SurveyComment />
            {sendButton}
            <p onClick={backStep} className={style.cancel}>
              気になるニュースを選びなおす
            </p>
          </>
        )}

        {isQuestionLocation && (
          <>
            <SurveySingleChoice id={id ? +id : 1} />
            {sendButton}
            <p onClick={backStep} className={style.cancel}>
              {history.location.pathname === `/question/${questionList[0].questionId}`
                ? '気になるニュースのコメントを書きなおす'
                : '前の質問へもどる'}
            </p>
          </>
        )}
        <p className={style.footer}>
          登録いただいた情報をもとに、個人を特定できないよう加工した統計データを作成いたします。
        </p>
      </form>
    </>
  );
};
