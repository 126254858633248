import { FC, useCallback, useMemo } from 'react';

// liff
import liff from '@line/liff';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// assets
import style from '../assets/style/components/hasAnswer.module.scss';

// function component
export const HasAnswer: FC = () => {
  const { point, isPost } = useSelector((state: RootState) => state.survey);
  const { thanksImage, thanksTitle, thanksButtonLabel, thanksSendMessage } = useSelector(
    (state: RootState) => state.custom
  );

  // ポイント表示の有無
  const hasMyPointInformation = useMemo(() => {
    return !point ? false : point.added !== 0;
  }, [point]);

  // ボタンの有無
  const hasPresentButton = useMemo(() => {
    return thanksSendMessage && thanksButtonLabel;
  }, [thanksSendMessage, thanksButtonLabel]);

  const handleSendMessage = useCallback(() => {
    // トークルームへメッセージ送信
    if (thanksSendMessage)
      liff
        .sendMessages([
          {
            type: 'text',
            text: thanksSendMessage,
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch(err => {
          console.error(err);
        });
  }, [thanksSendMessage]);

  return (
    <article className={style.article}>
      <header>
        <img
          src={`/assets/images/thanksImage/thanksImage_0.${
            thanksImage.split('/').slice(-1)[0].split('.').slice(-1)[0]
          }`}
          alt=""
          className={style.customImage}
        />
        {thanksTitle && thanksTitle.trim() !== '' && (
          <h2
            className={style.pageTitle}
            dangerouslySetInnerHTML={{ __html: thanksTitle.replace(/\n/g, '<br />') }}
          ></h2>
        )}
      </header>
      {hasMyPointInformation && (
        <div className={style.body}>
          <p className={style.pointGet}>{point.added}ポイントGET</p>
          <p className={style.myPoint}>
            あなたの保有ポイントは…
            <br />
            <strong>✨{point.validTotal}ポイント✨</strong>
          </p>
        </div>
      )}
      <footer className={style.footer}>
        {/* アンケートPOST後 かつ プレゼントボタンの情報がある */}
        {isPost && hasPresentButton && (
          <button type="button" className={style.button} onClick={handleSendMessage}>
            {thanksButtonLabel}
          </button>
        )}
      </footer>
    </article>
  );
};
