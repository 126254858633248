import { FC, memo, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { updateDraft } from '../store/user';

// assets
import style from '../assets/style/components/edit.module.scss';

// type
import { Occupation } from '../@types/profile';

// function component
export const EditOccupation: FC = () => {
  const dispatch = useDispatch();

  // 見出し
  const { isUserRegistered } = useSelector((state: RootState) => state.user);
  type InformationProps = {
    isEdit: boolean;
  };
  const Information = memo<InformationProps>(({ isEdit }) => (
    <h3 className={style.question}>
      {isEdit ? 'ご職業を選択してください' : 'ご職業を変更しますか？'}
    </h3>
  ));

  // 選択肢
  const options = useSelector((state: RootState) => state.user.profileOptions.occupation);

  // 初期値
  const defaultValue = useSelector((state: RootState) => state.user?.draft?.occupation);

  // 値を変更するとstoreへ反映
  const doChange = useCallback(
    e => {
      const newValue = +e.target.value as Occupation;
      dispatch(updateDraft({ label: 'occupation', value: newValue }));
    },
    [dispatch]
  );

  return (
    <>
      <Information isEdit={!isUserRegistered} />
      <ul className={style.occupationOptions}>
        {options.map(({ label, value, description }) => (
          <li key={`occupation_${value}`} data-type={value}>
            <input
              className={style.radio}
              id={`occupation_${value}`}
              type="radio"
              name="occupation"
              value={value}
              defaultChecked={defaultValue === value}
              onChange={doChange}
            />
            <label className={style.occupationLabel} htmlFor={`occupation_${value}`}>
              {label}
              {description ? <small className={style.description}>{description}</small> : undefined}
            </label>
          </li>
        ))}
      </ul>
    </>
  );
};
