import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import dialog from './dialog';
import custom from './custom';
import survey, { surveyApi } from './survey';
import user, { userApi } from './user';

const reducer = combineReducers({
  dialog,
  custom,
  survey,
  [surveyApi.reducerPath]: surveyApi.reducer,
  user,
  [userApi.reducerPath]: userApi.reducer,
});

const middleware = getDefaultMiddleware({ serializableCheck: false }).concat(
  surveyApi.middleware,
  userApi.middleware
);

export const store = configureStore({ reducer, middleware });

export type RootState = ReturnType<typeof reducer>;
