import { render } from 'react-dom';

// router
import { Routes } from './routes/routes';

// redux
import { Provider } from 'react-redux';
import { store } from './store/index';

// asset
import './assets/style/index.scss';

// 画面の高さをmin-heightとする
const rootDom = document.getElementById('root');
const doWindowResize = () => {
  if (rootDom) rootDom.style.setProperty('min-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', doWindowResize, false);
doWindowResize();

render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);
