import { FC, memo, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { updateDraft } from '../store/user';

// assets
import style from '../assets/style/components/edit.module.scss';

// type
import { Gender } from '../@types/profile';

// function component
export const EditGender: FC = () => {
  const dispatch = useDispatch();

  // 見出し
  const { isUserRegistered } = useSelector((state: RootState) => state.user);
  type InformationProps = {
    isEdit: boolean;
  };
  const Information = memo<InformationProps>(({ isEdit }) => (
    <h3 className={style.question}>
      {isEdit ? 'あなたの性別を教えてください' : '性別を変更しますか？'}
    </h3>
  ));

  // 選択肢
  const options = useSelector((state: RootState) => state.user.profileOptions.gender);

  // 初期値
  const defaultValue = useSelector((state: RootState) => state.user?.data?.gender);

  // 値を変更するとstoreへ反映
  const doChange = useCallback(
    e => {
      const newValue = +e.target.value as Gender;
      dispatch(updateDraft({ label: 'gender', value: newValue }));
    },
    [dispatch]
  );

  return (
    <>
      <Information isEdit={!isUserRegistered} />
      <ul className={style.genderOptions}>
        {options.map(({ label, value }) => (
          <li key={`gender_${value}`} data-type={value}>
            <input
              className={style.radio}
              id={`gender_${value}`}
              type="radio"
              name="gender"
              value={value}
              defaultChecked={defaultValue === value}
              onChange={doChange}
            />
            <label className={style.genderLabel} htmlFor={`gender_${value}`}>
              {label}
            </label>
          </li>
        ))}
      </ul>
    </>
  );
};
