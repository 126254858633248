import { FC } from 'react';

// redux

// assets
import style from '../../assets/style/pages/profile.module.scss';

// component
import { Layout } from '../../layout/layout';
import { Confirm } from '../../components/confirm';
import { TermsOfUse } from '../../components/termsOfUse';

// ヘッダー
const customHeader = (
  <div className={style.information}>
    <h2 className={style.pageTitle}>プロフィール</h2>
  </div>
);

// function component
export const ProfileHome: FC = () => {
  return (
    <Layout customHeader={customHeader}>
      <div className={style.profile}>
        <Confirm isEditable={true} />
      </div>
      <TermsOfUse />
    </Layout>
  );
};
