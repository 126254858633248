import { FC, memo, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { updateDraft } from '../store/user';

// hooks
import { useProfileLabel } from '../hooks/useProfileLabel';

// assets
import style from '../assets/style/components/edit.module.scss';

// type
import { Prefecture } from '../@types/profile';

// function component
export const EditPrefecture: FC = () => {
  const dispatch = useDispatch();

  // 見出し
  const { isUserRegistered } = useSelector((state: RootState) => state.user);
  type InformationProps = {
    isEdit: boolean;
  };
  const Information = memo<InformationProps>(({ isEdit }) => (
    <h3 className={style.question}>
      {isEdit ? 'お住まいのエリアを選択してください' : 'お住まいのエリアを変更しますか？'}
    </h3>
  ));

  // 選択肢
  const options = useSelector((state: RootState) => state.user.profileOptions.prefecture);

  // 初期値
  const defaultValue = useSelector((state: RootState) => state.user?.draft?.prefecture);
  const currentLabel = useProfileLabel({ category: 'prefecture', value: defaultValue });

  // 値を変更するとstoreへ反映
  const doChange = useCallback(
    e => {
      const newValue = +e.target.value as Prefecture;
      dispatch(updateDraft({ label: 'prefecture', value: newValue }));
    },
    [dispatch]
  );

  return (
    <>
      <Information isEdit={!isUserRegistered} />
      <label className={style.select} htmlFor="age">
        <span>{currentLabel || '未選択'}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
        <select id="prefecture" defaultValue={defaultValue ?? ''} onChange={doChange}>
          {defaultValue === undefined ? <option value="">{'未選択'}</option> : undefined}
          {options.map(({ label, value }) => (
            <option key={`prefecture_${value}`} value={value}>
              {label}
            </option>
          ))}
        </select>
      </label>
    </>
  );
};
