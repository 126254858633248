import { FC } from 'react';

// router
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// pages
import { NotAccepting } from '../pages/notAccepting';
import { Onboarding } from '../pages/onboarding';
import { Home } from '../pages/home';
import { ProfileHome } from '../pages/profile/profile';
import { ProfileEdit } from '../pages/profile/edit';

// layout
import { Dialog } from '../layout/dialog';

// function component
type Props = {
  isAccepting: boolean;
  isUserRegistered: boolean;
};
export const Private: FC<Props> = ({ isAccepting, isUserRegistered }) => {
  return (
    <BrowserRouter>
      <Switch>
        {!isAccepting && !isUserRegistered ? ( // 受付時間外 + ユーザー登録前
          <>
            <Route exact path="/" component={NotAccepting} />
            <Route exact path="/profile" component={Onboarding} />
          </>
        ) : undefined}

        {!isAccepting && isUserRegistered ? ( // 受付時間外 + ユーザー登録済
          <>
            <Route exact path="/" component={NotAccepting} />
            <Route exact path="/profile/" component={ProfileHome} />
            <Route exact path="/profile/:id" component={ProfileEdit} />
            <Route exact path="/comment/">
              <Redirect to="/" />
            </Route>
            <Route exact path="/question/:id">
              <Redirect to="/" />
            </Route>
          </>
        ) : undefined}

        {isAccepting && !isUserRegistered ? ( // ユーザー登録前
          <>
            <Route exact path="/" component={Onboarding} />
          </>
        ) : undefined}

        {isAccepting && isUserRegistered ? ( // ユーザー登録済
          <>
            <Route exact path="/" component={Home} />
            <Route exact path="/comment/" component={Home} />
            <Route exact path="/question/:id" component={Home} />
            <Route exact path="/profile/" component={ProfileHome} />
            <Route exact path="/profile/:id" component={ProfileEdit} />
          </>
        ) : undefined}
      </Switch>
      <Dialog />
    </BrowserRouter>
  );
};
