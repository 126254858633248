import { FC } from 'react';

// assets
import style from '../assets/style/components/termsOfUse.module.scss';

export const TermsOfUse: FC = () => {
  return (
    <div className={style.termsOfUse}>
      <p className={style.note}>
        TBSは、本アンケート結果の統計データを作成する目的で、
        性別、年齢、居住地をおうかがいしています。統計データは、個人を特定できないよう加工して作成いたします。
      </p>
      <p className={style.note}>
        <a className={style.privacy} href="https://www.tbsholdings.co.jp/tbstv/privacy/net.html">
          TBSのインターネットの個人情報保護について
        </a>
      </p>
    </div>
  );
};
