import { FC, ReactNode } from 'react';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// hooks
import { useLayout } from '../hooks/useLayout';
import { useCancelProfileUpdate } from '../hooks/useCancelProfileUpdate';

// component
import { GlobalNavigation } from './globalNavigation';

// assets
import style from '../assets/style/layout/layout.module.scss';

// function component
type Props = {
  children: ReactNode;
  customHeader?: ReactNode;
  customFooter?: ReactNode;
};
export const Layout: FC<Props> = ({ children, customHeader, customFooter }) => {
  const { isUserRegistered } = useSelector((state: RootState) => state.user);
  const { isAccepting } = useSelector((state: RootState) => state.survey);

  useLayout();
  useCancelProfileUpdate();

  return (
    <div
      className={[style.layout, !customFooter ? style.hasGlobalNavigation : undefined].join(' ')}
      aria-busy={isUserRegistered === null || isAccepting === null ? 'true' : undefined}
    >
      <div className={style.header}>{customHeader}</div>
      <main className={style.body}>{children}</main>
      <div className={`style.footer`}>{customFooter || <GlobalNavigation />}</div>
    </div>
  );
};
