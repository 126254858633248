// sanitize-html
// https://www.npmjs.com/package/sanitize-html
import sanitizeHtml from 'sanitize-html';

// emoji-regex
// https://github.com/mathiasbynens/emoji-regex
import emojiRegex from 'emoji-regex';

// サニタイジングしてEmojiを取り除いた文字列を返す
export const useDeleteEmoji = (value: string): string => {
  // Emoji検知の正規表現
  const regEmoji = emojiRegex();

  return sanitizeHtml(value).trim().replace(regEmoji, '');
};
