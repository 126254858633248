import { FC, useCallback, useRef, useMemo, useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { updateQuestionAnswerList } from '../store/survey';

// hooks
import { useDeleteEmoji } from '../hooks/useDeleteEmoji';

// assets
import style from '../assets/style/components/surveySingleChoice.module.scss';
import articleStyle from '../assets/style/components/survey.module.scss';

// type
type Props = {
  id: number;
};

// function component
export const SurveySingleChoice: FC<Props> = (props: Props) => {
  const { id } = props;
  const { questionList, surveyAnswer } = useSelector((state: RootState) => state.survey);
  const currentQuestion = questionList.find(item => item.questionId === id);
  const currentAnswer = useMemo(
    () => surveyAnswer.questionAnswerList?.find(item => item.questionId === id),
    [id, surveyAnswer.questionAnswerList]
  );

  const dispatch = useDispatch();

  const answer = useMemo(() => currentAnswer?.answer, [currentAnswer]);
  const comment = useMemo(() => currentAnswer?.comment || '', [currentAnswer]);

  const deleteEmoji = useDeleteEmoji; // Emoji除去

  // コメント入力欄
  const commentInput = useRef<HTMLTextAreaElement>(null);
  const commentLimit = 500;
  const [commentRemaining, setCommentRemaining] = useState<number>(500);
  const updateLimit = useCallback(
    (value: string): void => {
      const preText = deleteEmoji(value ?? '').slice(0, 500);
      setCommentRemaining(() => commentLimit - preText.length);
    },
    [deleteEmoji]
  );

  // 選択肢を指定
  const doChange = useCallback(
    e => {
      const currentComment = comment === '' ? undefined : comment;
      dispatch(
        updateQuestionAnswerList({
          questionId: id,
          answer: +e.currentTarget.value,
          comment: currentComment,
        })
      );
    },
    [comment, dispatch, id]
  );

  // コメントの書き込み
  const doComment = useCallback(
    e => {
      const newValue = e.target.value as string;
      const preText = deleteEmoji(newValue ?? '').slice(0, 500);
      if (answer !== undefined) {
        const currentComment = preText === '' ? undefined : preText;
        dispatch(
          updateQuestionAnswerList({
            questionId: id,
            answer,
            comment: currentComment,
          })
        );
      }
    },
    [answer, deleteEmoji, dispatch, id]
  );

  // フォーカスが外れたタイミングで入力欄の値を反映
  const doBlur = useCallback(
    e => {
      e.target.value = comment ?? '';
    },
    [comment]
  );

  // ページを表示したとき状態管理で持っている値をラジオボタンのchecked代替として選択中の表示を再現させる
  const isChecked = useCallback(
    (value): boolean => {
      return answer === value;
    },
    [answer]
  );

  // コメント入力textareaの監視
  useEffect(() => {
    // ページ読込時にコメントの残り文字数を反映
    if (comment !== undefined) updateLimit(comment);
    // 状態管理しているコメントの値が空になったらtextareaを空欄にする
    if (commentInput && commentInput.current && comment === '') commentInput.current.value = '';
  }, [comment, commentInput, updateLimit]);

  // ラジオボタンへ前の質問の値が残る不具合対応：遷移するとラジオボタンのみ値をリセット
  useEffect(() => {
    const resetTargetList = document.querySelectorAll('input[type="radio"]');
    Array.prototype.slice.call(resetTargetList).forEach((item, index) => {
      item.checked = false;
    });
  }, [id]);

  return (
    <>
      <article className={style.singleChoice}>
        <header>
          <h2 className={style.question}>{currentQuestion?.question}</h2>
        </header>

        <div className={articleStyle.article__body}>
          <ul className={style.list}>
            {currentQuestion?.choices.map((select, index) => {
              return (
                <li className={style.item} key={index}>
                  <input
                    className={articleStyle.radio}
                    type="radio"
                    id={`question${id}_${index}`}
                    name={`question${id}`}
                    value={index}
                    defaultChecked={answer ? answer === index : false}
                    onChange={doChange}
                  />
                  <label
                    data-checked={isChecked(index)}
                    className={articleStyle.radioLabel}
                    htmlFor={`question${id}_${index}`}
                  >
                    {select}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </article>
      {currentQuestion?.isCommentable && currentQuestion?.commentNote && (
        <article className={articleStyle.article}>
          <header>
            <h2 className={articleStyle.question}>
              {currentQuestion?.commentNote}
              <small>（任意）</small>
            </h2>
          </header>

          <div className={articleStyle.article__body}>
            <textarea
              ref={commentInput}
              onInput={doComment}
              onBlur={doBlur}
              className={articleStyle.textarea}
              cols={40}
              rows={8}
              defaultValue={comment}
              disabled={answer === undefined}
              placeholder={answer === undefined ? 'コメントする項目を選んでください' : undefined}
            ></textarea>
            <div className={articleStyle.textareaCounter}>
              {commentRemaining} &#047; {commentLimit}
            </div>
          </div>
        </article>
      )}
    </>
  );
};
