import { FC, memo, useEffect, useState, useCallback } from 'react';

// router
import { useHistory } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { useSendProfileMutation, updateIsUserRegistered, updateDraft } from '../store/user';
import { updateOpen, updateContent } from '../store/dialog';

// components
import { Layout } from '../layout/layout';
import { TermsOfUse } from '../components/termsOfUse';
import { StepNavigation } from '../components/stepNavigation';
import { EditGender } from '../components/editGender';
import { EditAge } from '../components/editAge';
import { EditPrefecture } from '../components/editPrefecture';
// import { EditOccupation } from '../components/editOccupation';
import { EditNickname } from '../components/editNickname';
import { Confirm } from '../components/confirm';

// assets
import style from '../assets/style/pages/onboarding.module.scss';
import dialogStyle from '../assets/style/layout/dialog.module.scss';

// type
import { Profile } from '../@types/profile';

// function component
export const Onboarding: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId, draft } = useSelector((state: RootState) => state.user);
  const { isAccepting } = useSelector((state: RootState) => state.survey);

  const [step, setStep] = useState<number>(1);
  const finish = 5;

  // 送信結果ダイアログ
  type ResultDialogProps = { isSuccess: boolean };
  const ResultDialog = memo<ResultDialogProps>(({ isSuccess }) => {
    // 閉じる
    const doClose = useCallback(() => {
      if (isSuccess) dispatch(updateIsUserRegistered());
      history.push(isAccepting ? '/' : '/profile');
      dispatch(updateOpen(false)); // 閉じる
    }, [isSuccess]);

    return (
      <>
        <div className={dialogStyle.body}>
          {isSuccess ? (
            <>
              <h2 className={dialogStyle.title}>ありがとうございます</h2>
              <p className={dialogStyle.description}>
                ご登録内容は、プロフィールメニューからいつでも編集できます。
              </p>
            </>
          ) : (
            <p className={dialogStyle.description}>'登録に失敗しました'</p>
          )}
        </div>
        {isAccepting ? (
          <button className={dialogStyle.button} type="button" onClick={doClose}>
            アンケートに回答する
          </button>
        ) : (
          <div className={dialogStyle.confirmFooter}>
            <button className={dialogStyle.buttonCancel} type="button" onClick={doClose}>
              閉じる
            </button>
          </div>
        )}
      </>
    );
  });

  // プロフィールを送信
  const [
    sendProfile, // mutation trigger
    { isLoading: isUpdating, isError: isUpdatingError, isSuccess: isUpdatingSuccess }, // mutation state
  ] = useSendProfileMutation();

  const doUpdate = useCallback(() => {
    // 確認画面へ
    if (step === finish) {
      const sendData = {
        userId: userId as string,
        data: { ...draft } as Profile,
      };
      sendProfile(sendData);
    } else {
      setStep(pre => pre + 1);
      // ニックネーム初期値に空文字を設定
      if (step === 1) dispatch(updateDraft({ label: 'nickname', value: '' }));
    }
  }, [dispatch, draft, sendProfile, step, userId]);
  useEffect(() => {
    if (isUpdatingSuccess) dispatch(updateContent(<ResultDialog isSuccess={true} />));
    if (isUpdatingError) dispatch(updateContent(<ResultDialog isSuccess={false} />));
    if (isUpdatingSuccess || isUpdatingError) {
      // ダイアログで告知
      dispatch(updateOpen(true));
    }
  }, [history, isUpdatingSuccess, isUpdatingError, ResultDialog, dispatch]);

  // ボタン非活性判定
  const isDisabled = useCallback((): boolean => {
    let isInvalid = false;
    if (step === 1 && draft?.gender === undefined) {
      isInvalid = true;
    } else if (step === 2 && draft?.age === undefined) {
      isInvalid = true;
    } else if (step === 3 && draft?.prefecture === undefined) {
      isInvalid = true;
      // } else if (step === 4 && draft?.occupation === undefined) {
      //   isInvalid = true;
    } else if (isUpdating) {
      isInvalid = true;
    }

    return isInvalid;
  }, [draft, step, isUpdating]);

  // 登録に成功したらhomeへ遷移
  useEffect(() => {
    if (step === finish && isUpdatingSuccess) history.push('/');
  }, [history, step, isUpdatingSuccess]);

  // 独自ヘッダー
  const customHeader = (
    <div className={style.header} data-step={step}>
      <h1 className={style.pageTitle}>
        {step < finish ? (
          <>
            プロフィール情報の
            <br />
            登録をお願いします
          </>
        ) : (
          <>
            ありがとうございます
            <br />
            こちらの内容で登録いたします
          </>
        )}
      </h1>
      <StepNavigation step={step} />
    </div>
  );

  // 独自フッター
  const customFooter = <TermsOfUse />;

  return (
    <Layout customHeader={customHeader} customFooter={customFooter}>
      <div className={style.form}>
        {step === 1 ? (
          <EditGender />
        ) : step === 2 ? (
          <EditAge />
        ) : step === 3 ? (
          <EditPrefecture />
        ) : step === 4 ? (
          //   <EditOccupation />
          // ) : step === 5 ? (
          <EditNickname />
        ) : (
          <>
            <Confirm />
            <p className={style.note}>プロフィールは、登録後も編集できます。</p>
          </>
        )}

        <button className={style.submit} type="button" onClick={doUpdate} disabled={isDisabled()}>
          {step < finish ? '次へ' : '登録する'}
        </button>
      </div>
    </Layout>
  );
};
