import { FC, memo, useCallback } from 'react';

// router
import { Link } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// assets
import style from '../assets/style/pages/notAccepting.module.scss';

// component
import { Layout } from '../layout/layout';
import { TermsOfUse } from '../components/termsOfUse';

// type

// function component
export const NotAccepting: FC = () => {
  const { isUserRegistered } = useSelector((state: RootState) => state.user);

  // 独自フッター
  const customFooter = !isUserRegistered ? (
    <>
      <p className={style.profile}>
        <Link className={style.link} to="/profile">
          先にプロフィールを登録しておく
        </Link>
      </p>
      <TermsOfUse />
    </>
  ) : undefined;

  // 数値を0詰めの2桁の文字に変換して返す
  const doubleDigits = useCallback((value: number): string => {
    return ('00' + value).slice(-2);
  }, []);

  const AcceptingInformation = memo(() => {
    // 環境変数で指定した受付期間
    const acceptingDaysText = (process.env.REACT_APP_ACCEPTING_DAYS || '') as string; // 受付曜日
    const acceptingDays = acceptingDaysText.split(',').map(day => +day.trim()); // 数値化して配列に変換
    const acceptingTimeText = (process.env.REACT_APP_ACCEPTING_TIME || '') as string; // 受付時間
    const acceptingTime = acceptingTimeText.split('-').map(time => time.trim()); // 配列に変換

    // 今の情報
    const now = new Date();
    const nowYear = now.getFullYear();
    const nowMonth = doubleDigits(now.getMonth() + 1);
    const nowDate = doubleDigits(now.getDate());

    // 今日がアンケート実施曜日かどうか
    const nowDay = now.getDay();
    const hasAcceptingDays = acceptingDays.includes(nowDay);

    // 明日以降の開催日を取得
    const findNextAcceptingDate = () => {
      let distance = 0;
      // 隣の曜日候補のリスト
      [0, 1, 2, 3, 4, 5, 6].forEach(index => {
        const pointer = nowDay + index;
        const result = pointer <= 6 ? pointer : pointer - 7;
        if (acceptingDays.includes(result) && distance === 0) distance = index; // 隣の曜日が最初に該当する受付曜日かどうか
      });
      return new Date(now.setDate(now.getDate() + distance));
    };

    // 次回開催日を判定
    const isOver =
      +now > +new Date(`${nowYear}-${nowMonth}-${nowDate}T${acceptingTime[1]}:00+09:00`); // 今日がアンケート実施曜日の場合、実施後かどうか

    const nextAccepting = () => {
      const targetDatetime = hasAcceptingDays && !isOver ? now : findNextAcceptingDate();
      const resultYear = targetDatetime.getFullYear();
      const resultMonth = targetDatetime.getMonth() + 1;
      const resultDate = targetDatetime.getDate();
      return `${resultYear}年${resultMonth}月${resultDate}日 ${acceptingTimeText.replace(
        /-/g,
        ' - '
      )}`;
    };

    return (
      <dl className={style.dl}>
        <dt>次回の実施</dt>
        <dd>{nextAccepting()}</dd>
      </dl>
    );
  });

  return (
    <Layout customFooter={customFooter}>
      <article className={style.article}>
        <header>
          <img src="/assets/images/thanksImage/logo.png" alt="THE TIME," className={style.logo} />
          <h2 className={style.pageTitle}>
            いつもありがとうございます
            <br />
            次回のニュース関心調査も
            <br />
            ぜひご参加ください
          </h2>
        </header>
        <AcceptingInformation />
        <p className={style.body}>
          アンケートでポイントをGETして
          <br />
          豪華プレゼントに応募しよう❣️
        </p>
        <p className={style.body}>
          ニュース関心調査は、
          <br />
          放送前日のよる7時〜9時に実施中!!
          <br />
          アンケートが始まる時は
          <br />
          トークルームでお知らせするよ🐥
        </p>
      </article>
    </Layout>
  );
};
