import { createSlice } from '@reduxjs/toolkit';
import customData from '../data/customData.json';

// microCMSからGETするデータ
type CustomData = {
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  revisedAt: string;
  thanksImage: {
    url: string;
    height: number;
    width: number;
  };
  thanksTitle?: string;
  thanksButtonLabel?: string;
  thanksSendMessage?: string;
};

type State = {
  thanksImage: string; // アンケート回答完了画面の見出し画像
  thanksTitle?: string; // アンケート回答完了画面の見出し
  thanksButtonLabel?: string; // アンケート回答完了画面のボタンラベル
  thanksSendMessage?: string; // アンケート回答完了画面のボタン押下でトークルームへ送信するテキスト
};

const initialState: State = {
  thanksImage: (customData as CustomData).thanksImage.url.split('/').slice(-1)[0],
  thanksTitle: (customData as CustomData).thanksTitle,
  thanksButtonLabel: (customData as CustomData).thanksButtonLabel,
  thanksSendMessage: (customData as CustomData).thanksSendMessage,
};

const custom = createSlice({
  name: 'custom',

  initialState,

  reducers: {},
});

// Action Creator
// export const {  } = custom.actions;

// Reducer
export default custom.reducer;
