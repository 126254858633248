import { FC, memo, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { updateDraft } from '../store/user';

// hooks
import { useDeleteEmoji } from '../hooks/useDeleteEmoji';

// assets
import style from '../assets/style/components/edit.module.scss';

// type
import { Nickname } from '../@types/profile';

// function component
export const EditNickname: FC = () => {
  const dispatch = useDispatch();

  const deleteEmoji = useDeleteEmoji; // Emoji除去

  // 見出し
  const { isUserRegistered } = useSelector((state: RootState) => state.user);
  type InformationProps = {
    isEdit: boolean;
  };
  const Information = memo<InformationProps>(({ isEdit }) => (
    <h3 className={style.question}>
      {isEdit ? 'ニックネームを登録できます（任意）' : 'ニックネームを変更しますか？'}
    </h3>
  ));

  // 初期値
  const defaultValue = useSelector((state: RootState) => state.user?.draft?.nickname);

  // 値を変更するとstoreへ反映
  const doChange = useCallback(
    e => {
      const newValue = e.target.value as Nickname;
      dispatch(
        updateDraft({
          label: 'nickname',
          value: deleteEmoji(newValue ?? '').slice(0, 40),
        })
      );
    },
    [dispatch, deleteEmoji]
  );

  // フォーカスが外れたタイミングで入力欄の値を反映
  const doBlur = useCallback(
    e => {
      e.target.value = defaultValue ?? '';
    },
    [defaultValue]
  );

  return (
    <>
      <Information isEdit={!isUserRegistered} />
      <input
        className={style.textbox}
        type="text"
        maxLength={40}
        onInput={doChange}
        onBlur={doBlur}
        defaultValue={defaultValue}
        placeholder="未登録の場合は匿名で紹介します"
      />
      <p className={style.note}>放送内で、コメントをご紹介するときに表示します。</p>
    </>
  );
};
