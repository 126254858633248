import { FC } from 'react';

// router
import { Link, useLocation } from 'react-router-dom';

// assets
import style from '../assets/style/layout/globalNavigation.module.scss';

export const GlobalNavigation: FC = () => {
  const location = useLocation();
  return (
    <nav className={style.globalNavigation}>
      <ul className={style.list}>
        <li className={style.item}>
          <Link
            className={style.link}
            to="/"
            aria-current={!location.pathname.match(/profile/g) ? 'page' : undefined}
          >
            <svg className={style.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M22,7h-9v2h9V7z M22,15h-9v2h9V15z M5.54,11L2,7.46l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L5.54,11z M5.54,19L2,15.46 l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L5.54,19z" />
            </svg>
            アンケート
          </Link>
        </li>
        <li className={style.item}>
          <Link
            className={style.link}
            to="/profile"
            aria-current={!!location.pathname.match(/profile/g) ? 'page' : undefined}
          >
            <svg className={style.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
            </svg>
            プロフィール
          </Link>
        </li>
      </ul>
    </nav>
  );
};
