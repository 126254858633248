import { useEffect } from 'react';

// router
import { useHistory } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { updateDraft } from '../store/user';

// type
import { ProfileValue } from '../@types/profile';

// プロフィールを編集後APIへ送信せずページ遷移した場合はリセットする
export const useCancelProfileUpdate = (): void => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, draft, isUserRegistered } = useSelector((state: RootState) => state.user);

  // ページ遷移したときだけ発動
  useEffect(() => {
    if (isUserRegistered && data && draft) {
      const savedData = Object.assign({}, data) as { [key: string]: ProfileValue };
      const draftData = Object.assign({}, draft) as { [key: string]: ProfileValue };

      Object.keys(savedData).forEach(key => {
        const draftValue = draftData[key];
        if (draftValue !== savedData[key])
          dispatch(updateDraft({ label: key, value: savedData[key] }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
};
